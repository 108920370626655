.locations-container {
    text-align: center;
    padding: 10px;
    background-color: #f4f4f4;
  }
  
.locais-banner {
    background-color: #721E1B;
    color: white;
    padding: 10px 20px;
    font-weight: bold;
    text-align: center;
    border-radius: 3px;
    display: inline-block;
    margin: -10%;
    position: relative;
    margin-top: -20px;
    margin-bottom: 10px;
}

.locais-banner:before,
.locais-banner:after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: 10px solid transparent;
}

.locais-banner:before {
    left: 188px;
    border-right-color: #f4f4f4;
}

.locais-banner:after {
    right: 188px;
    border-left-color: #f4f4f4;
}

.h1-local {
    margin-bottom: 10px;
    font-size: 32px;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.30));
}

.menu-button2 {
    display: center;
    width: 110%;
    padding: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .menu-button2 {
    border-radius: 25px;
    background-color: #721E1B;
    color: white;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.55))
  }

  .menu-button2:hover {
    background-color: #8B0000;
  }

  .locationsdown {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .locationdown {
    background: #f4f4f4;
    padding: 15px;
    margin: 10px;
    width: 100%;
    max-width: 430px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.locationdown p {
  color: #2e2e2e;
  margin: 3px 0;
  line-height: 1.5;
  text-align: center;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.25))
}

.locationdown h2 {
    color: #000000;
    margin: 5px 0;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.25))
  }
  

  @media (max-width: 768px) {
    .h1-local {
        margin-top: 8%;
        font-size: 22px;
    }

    .locationdown h2 {
        font-size: 20px;
      }

    .locationdown p {
        font-size: 13px;
    }

    .menu-button2 {
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 5px;
        width: 100%;
    }
}


