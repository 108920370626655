.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #721E1B;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: background-color 2s ease, opacity 0.5s ease, visibility 0.5s ease;
  opacity: 1;
  visibility: visible;
}

.loading-screen.hidden {
  opacity: 0;
  visibility: hidden;
}

.loading-screen.phase-1 {
  background-color: #ffffff; 
}

.loading-screen.phase-2 .logo-container {
  display: block;
  animation: logoAppear 2s ease forwards;
}

.loading-screen.phase-3 {
  animation: closeTV 0.5s ease forwards;
}

.logo-container {
  display: none;
}

.logo-screen {
  width: 500px; /* Ajuste o tamanho da logo conforme necessário */
  margin: 0 auto; /* Centraliza a logo */
  display: flex; 
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
}

@keyframes logoAppear {
  from {
      opacity: 0;
      transform: scale(0);
  }
  to {
      opacity: 1;
      transform: scale(1);
  }
}

@keyframes closeTV {
  0% {
      transform: scale(1, 1);
      opacity: 1;
  }
  50% {
      transform: scale(1, 0.1);
      opacity: 0.7;
  }
  100% {
      transform: scale(1, 0);
      opacity: 0;
  }
}

@media (max-width: 768px) {
  .logo-screen {
      width: 250px; /* Ajuste o tamanho da logo conforme necessário */
      margin: 0 auto; /* Centraliza a logo */
  }
}
