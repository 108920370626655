.header {
    background-color: #000;
    padding: 1rem;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar-links {
    list-style: none;
    display: flex;
    gap: 2rem;
    margin: 0;
    flex: 1;
  }
  
  .navbar-links li a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .navbar-icons {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 2%;
    size: 30pc;
  }
  
  .navbar-icons a {
    color: #fff;
    margin-left: 1.5rem;
  }
  
  .navbar-icons i {
    font-size: 1.5rem;
  }
  
  .menu-toggle {
    display: none;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    width: 30px;
    height: 30px;
  }
  
  .menu-toggle span {
    background: #fff;
    height: 3px;
    width: 100%;
    margin: 4px 0;
    transition: all 0.3s;
  }
  
  .menu-toggle.open span:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }
  
  .menu-toggle.open span:nth-child(2) {
    opacity: 0;
  }
  
  .menu-toggle.open span:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .navbar {
      flex-wrap: nowrap;
    }
  
    .navbar-links {
      display: none;
      flex-direction: column;
      width: 100%;
      background-color: #000;
      position: absolute;
      top: 60px;
      left: 0;
      padding: 1rem 0;
      gap: 0.5rem;
    }
  
    .navbar-links.show {
      display: flex;
    }
  
    .menu-toggle {
      display: flex;
    }
  
    .navbar-icons {
      margin-left: 0;
    }
  }
  
  @media (max-width: 480px) {
    .navbar-logo img {
      height: 40px;
    }
  
    .navbar-icons i {
      font-size: 1.2rem;
    }
  }
  