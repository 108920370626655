/* Menu.css */
.menu {
    text-align: center;
    padding: 40px 20px;
    background-image: url("https://live.staticflickr.com/65535/53864350810_7a9baf066b_k.jpg"); /* Adicione a imagem de fundo */
    background-size: cover;
    background-position: center;
    color: white;
  }
  
  .logo-container-menu {
    margin-top: 50px;
    margin-bottom: 35px;
    margin-left: -35px;
  }
  
  .logo-menu {
    width: 250px; /* Ajuste o tamanho da logo conforme necessário */
    margin: 0 auto; /* Centraliza a logo */
    display: flex; 
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.800));
  }
  
  .menu h2 {
    margin-bottom: 40px;
    font-size: 32px;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }
  
  .branch {
    background: rgba(51, 51, 51, 0.54); /* Fundo semi-transparente */
    padding: 20px;
    border-radius: 8px;
    display: inline-block; /* Para centralizar o conteúdo */
    text-align: center; /* Centraliza o texto dentro do branch */
  }
  
  .location {
    width: 500px; /* Ajusta a largura de cada localização */
    text-align: center;
    display: inline-block;
    vertical-align: top;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.50));
    
  }
  
  .location h3 {
    margin-bottom: -5%;
    font-size: 25px;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }

  .location h4 {
    margin-bottom: 20px;
    font-size: 20px;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }
  
  .divider {
    width: 2px; /* Largura da barrinha */
    height: 300px; /* Altura da barrinha */
    background-color: white; /* Cor da barrinha */
    display: inline-block;
    margin: 0 20px; /* Espaçamento lateral para a barrinha */
    vertical-align: top;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }

  @media (max-width: 1200px) {
    .divider {
        display: none;
    }
}
  
  .menu-button, .order-button {
    display: center;
    width: 60%;
    padding: 15px;
    margin-bottom: 15px;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .menu-button {
    border-radius: 25px;
    background-color: #721E1B;
    color: white;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.25))
  }
  
  .order-button {
    border-radius: 25px;
    background-color: #721E1B;
    color: white;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.25))
  }

  .menu-button:hover, .order-button:hover {
      background-color: #8B0000;
  }
  
  .whatsapp-icon {
    width: 18px; /* Ajuste o tamanho do ícone conforme necessário */
    height: 18px;
    margin-bottom: -1px;
    margin-left: 8px; /* Espaçamento entre o ícone e o texto */
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.25));
  }

  .location h3 {
    font-size: 25px;
    margin-bottom: -20px;
}

  .location h4 {
    font-size: 15px;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    .menu h2 {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .location {
        width: 90%; /* Ajuste a largura para caber melhor em telas menores */
        margin-bottom: 20px; /* Adicione margem inferior para espaçamento */
    }

    .branch {
        padding: 15px;
    }

    .location h3 {
        font-size: 18px;
        margin-bottom: 15px;
    }

    .menu-button, .order-button {
        width: 80%; /* Ajuste a largura para caber melhor em telas menores */
        padding: 12px;
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .menu h2 {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .location {
        width: 100%; /* Use 100% da largura em telas muito pequenas */
        margin-bottom: 15px; /* Adicione margem inferior para espaçamento */
    }

    .branch {
        padding: 10px;
    }

    .location h3 {
        font-size: 20px;
        margin-bottom: -20px;
    }

    .location h4 {
      font-size: 15px;
      margin-bottom: 20px;
    }

    .menu-button, .order-button {
        width: 75%; /* Use 100% da largura em telas muito pequenas */
        padding: 10px;
        font-size: 14px;
    }
}
