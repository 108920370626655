.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000;
    color: #fff;
    padding: 20px;
    text-align: center;
  }
  
  .footer-section {
    flex: 1;
    padding: 10px;
  }
  
  .footer-section h3 {
    margin-bottom: 4px;
  }

  a:link {
    color: #ffffff;
    text-decoration: none;
}

a:visited {
    color: #ffffff;
    text-decoration: none;
}

  
  .footer-logo {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  
  .footer-section p {
    margin: 5px 0;
    width: 100%;
  }
  
  .footer-section img {
    width: 130px;
    margin-right: 10px;
  }
  
  .social-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .social-icons a {
    color: #fff;
    text-decoration: none;
    margin: 5px 0;
    display: flex;
    align-items: center;
  }

  .social-text {
    margin-bottom: 2px;
    margin-left: 10px;
  }

  .footer-section p:hover {
    color: #ddd;
    cursor: pointer;
  }

  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
    }
  
    .footer-section {
      padding: 5px 0;
      text-align: center;
    }
  
    .social-icons {
      flex-direction: column;
    }
  
    .social-icons a {
      margin: 0 5px;
      margin-bottom: 4px;
    }

    .footer-section p {
        font-size: 12px;
      }

      .footer-section h3 {
        font-size: 15px;
      }
  }