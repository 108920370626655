.nossa-historia-banner {
    background-color: #721E1B;
    color: white;
    padding: 10px 20px;
    font-weight: bold;
    text-align: center;
    border-radius: 3px;
    display: inline-block;
    margin: -10%;
    position: relative;
    margin-bottom: 5px;
}

.nossa-historia-banner:before,
.nossa-historia-banner:after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: 10px solid transparent;
}

.nossa-historia-banner:before {
    left: 161px;
    border-right-color: #ffffff;
}

.nossa-historia-banner:after {
    right: 161px;
    border-left-color: #ffffff;
}

.nossa-historia {
    text-align: center;
    padding: 10px;
}

.storymenu {
    margin-bottom: 30px;
}

.h2-story {
    margin-bottom: 3px;
    font-size: 32px;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.30));
}

.carousel-container {
    position: relative;
    overflow: hidden;
    max-width: 90%;
    margin: auto;
}

.carousel-item {
    padding: 10px;
}

.carousel-item img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.description {
    margin-top: 20px;
    font-size: 16px;
    color: #000000;
    line-height: 1.5;
    text-align: center;
    width: 90%; 
    margin-left: auto;
    margin-right: auto;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.10));
}

.logoverde {
    width: 200px;
    margin: 0 auto;
    display: flex; 
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.45));
}

@media (max-width: 768px) {
    .carousel-item img {
        max-width: 180px;
    }

    .h2-story {
        margin-top: 8%;
        font-size: 22px;
    }

    .description {
        text-align: justify;
        margin-top: 20px;
        font-size: 13px;
        max-width: 80%; 
    }
}


@media (max-width: 1024px) {
    .carousel-item img {
        max-width: 180px;
    }

    .h2-story {
        margin-top: 8%;
        font-size: 22px;
    }
}
